import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UserTypePage from "./masterlist/UserType";
import UserListPage from "./masterlist/UserList";
import CustomerListPage from "./masterlist/CustomerList";
import VendorListPage from "./masterlist/VendorList";
import VehicleListPage from "./masterlist/VehicleList";
import DriverListPage from "./masterlist/DriverList";
import LocationList from "./masterlist/LocationList";
import GoodsList from "./masterlist/GoodsList";
import MessageList from "./masterlist/MessageList";
import DdList from "./masterlist/DdList";
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import './common.css';
import { useHistory } from "react-router-dom";


function Masterlist() {

    let history = useHistory();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
                return;
            }
            switch (event.key) {
                case 'Backspace':
                    event.preventDefault();
                    history.push('/')
                    break;
                default:
                    break;
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const [UserTypes, setUserTypes] = useState(false);
    const [UserList, setUserList] = useState(false);
    const [CustomerList, setCustomerList] = useState(false);
    const [VendorList, setVendorList] = useState(false);
    const [VehicleList, setVehicleList] = useState(false);

    const [active1, setActive1] = useState("usertype");

    const [reloadChild, setReloadChild] = useState(false);

    const handleReloadChild = () => {
        setReloadChild(prevState => !prevState);
    };

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);

    const deleteallselectedrows = () => {
        axios.post("https://rt-rl-api.aajkaa.in/usertypes/delete", isChecked, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            window.location.reload();
        });
    }

    const show = (btnclicked) => {
        setUserTypes(false);
        setUserList(false);
        setCustomerList(false);
        setVendorList(false);
        setVehicleList(false);
        btnclicked(true);
    };

    const [isChecked, setisChecked] = useState([]);

    return (
        <div>
            <Modal show={show1} onHide={handleClose1} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title>RT & RL</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <i className="fa-solid fa-triangle-exclamation text-danger"></i><span className="mx-3 text-primary">Are You Sure To Delete User</span>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mx-2" onClick={deleteallselectedrows}>Yes</button>
                    <Button variant="secondary" onClick={handleClose1}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <h2 className='text-primary text-center my-1'>Create/Modify MasterLists</h2>
            <div className="container-fluid">
                <Tabs defaultValue="top">
                    <TabList underlinePlacement="bottom" className="tab-list">
                        <Link to='/' className="btn btn-primary me-3"><i className="fa-solid fa-caret-left mx-2"></i>Back</Link>
                        <Tab value="UserTypes" indicatorPlacement="bottom">
                            <b>User Types</b>
                        </Tab>
                        <Tab value="UserList" indicatorPlacement="bottom">
                            <b>User List</b>
                        </Tab>
                        <Tab value="CustomerList" indicatorPlacement="bottom">
                            <b>Customer List</b>
                        </Tab>
                        <Tab value="VendorList" indicatorPlacement="bottom">
                            <b>Vendor List</b>
                        </Tab>
                        <Tab value="VehicleList" indicatorPlacement="bottom">
                            <b>Vehicle List</b>
                        </Tab>
                        <Tab value="DriverList" indicatorPlacement="bottom">
                            <b>Driver List</b>
                        </Tab>
                        <Tab value="LocationList" indicatorPlacement="bottom">
                            <b>Location List</b>
                        </Tab>
                        <Tab value="GoodsList" indicatorPlacement="bottom">
                            <b>Goods List</b>
                        </Tab>
                        <Tab value="MessageList" indicatorPlacement="bottom">
                            <b>Message List</b>
                        </Tab>
                        <Tab value="DdList" indicatorPlacement="bottom">
                            <b>DD Lists</b>
                        </Tab>
                    </TabList>
                    <TabPanel value="UserTypes">
                        <UserTypePage />
                    </TabPanel>
                    <TabPanel value="UserList">
                        <UserListPage />
                    </TabPanel>
                    <TabPanel value="CustomerList">
                        <CustomerListPage />
                    </TabPanel>
                    <TabPanel value="VendorList">
                        <VendorListPage />
                    </TabPanel>
                    <TabPanel value="VehicleList">
                        <VehicleListPage />
                    </TabPanel>
                    <TabPanel value="DriverList">
                        <DriverListPage />
                    </TabPanel>
                    <TabPanel value="LocationList">
                        <LocationList />
                    </TabPanel>
                    <TabPanel value="GoodsList">
                        <GoodsList />
                    </TabPanel>
                    <TabPanel value="MessageList">
                        <MessageList />
                    </TabPanel>
                    <TabPanel value="DdList">
                        <DdList />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default Masterlist;
