import { BrowserRouter as Router, Route, Switch, Link, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import Notallowed from "./pages/Notallowed";
import Loading from "./pages/Loading";
import RTRL from "./pages/RTRL";
import Newenquiry from "./pages/Newenquiry";
import Existingenquiry from "./pages/Existingenquiry";
import Vehicalstatus from "./pages/Vehicalstatus";
import Masterlist from "./pages/Masterlist";
import Wanverification from "./pages/Wanverification";
import Financialaccounting from "./pages/Financialaccounting";
import Messageing from "./pages/Messageing";
import Report from "./pages/Report";
import Adminmodule from "./pages/Adminmodule";
import Assetmanagement from "./pages/Assetmanagement";
import Closedcase from "./pages/Closedcase";
import Example from "./pages/Example";
import A from "./pages/A";

import { AuthContext } from "./helpers/AuthContext";
import axios from "axios";

import './App.css'


function App() {
  const [authState, setAuthState] = useState({
    username: "",
    id: 0,
    usertype: "",
    whatsappnumber: "",
    status: false,
  });

  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [showOnlineNotification, setShowOnlineNotification] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setOnlineStatus(true);
      setShowOnlineNotification(true);
      setTimeout(() => setShowOnlineNotification(false), 2000);
    };

    const handleOffline = () => setOnlineStatus(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    axios
      .get("https://rt-rl-api.aajkaa.in/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            username: response.data.username,
            id: response.data.id,
            usertype: response.data.usertype,
            whatsappnumber: response.data.whatsappnumber,
            status: true,
          });
        }
      });
  }, []);

  let history = useHistory();

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({ username: "", id: 0, status: false });
    history.push('/login');
    window.location.reload()
  };

  return (
    <div className="App">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          {/* class="navbar bg-dark border-bottom border-body" data-bs-theme="dark"> */}
          <nav className="navbar navbar-expand-lg " data-bs-theme="dark" style={{ "backgroundColor": "	#3a4664" }}>
            <div className="container-fluid">
              <Link className="navbar-brand text-white" to="/">Ravindra Logistics</Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {!authState.status ? (
                    <>
                      <li className="nav-item">
                        <a className="nav-link text-white" href="/login">Login</a>
                      </li>
                    </>
                  ) : (
                    <>
                      {/* <li className="nav-item">
                      </li> */}
                    </>
                  )}
                </ul>
                <span className="nav-link text-white me-4"><span className="fs-6">Logged in as: </span><span className="fs-5">{authState.username} ({authState.usertype})</span></span>
                {authState.status ? (
                  <div className="dropdown dropstart">
                    <button type="button" className="btn btn-success" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fa-solid fa-gear me-3"></i>Settings
                    </button>
                    <ul className="dropdown-menu" style={{ width: "fit-content" }}>
                      <li><a className="dropdown-item" href="/"><i className="fa-solid fa-user me-3"></i>Profile</a></li>
                      <li><button className="dropdown-item" onClick={logout}><i className="fa-solid fa-right-from-bracket me-2"></i> Logout</button></li>
                    </ul>
                  </div>)
                  :
                  <></>
                }
              </div>
            </div>
          </nav>
          <Switch>
            <Route path="/" exact component={RTRL} value={{ authState }} />
            <Route path="/login" exact component={Login} />
            <Route path="/notallowed" exact component={Notallowed} />
            <Route path="/loading" component={Loading} />
            <Route path="/Newenquiry" component={Newenquiry} />
            <Route path="/Existingenquiry" component={Existingenquiry} />
            <Route path="/Vehicalstatus" component={Vehicalstatus} />
            <Route path="/Masterlist" component={Masterlist} />
            <Route path="/Wanverification" component={Wanverification} />
            <Route path="/Financialaccounting" component={Financialaccounting} />
            <Route path="/Messageing" component={Messageing} />
            <Route path="/Report" component={Report} />
            <Route path="/Adminmodule" component={Adminmodule} />
            <Route path="/Assetmanagement" component={Assetmanagement} />
            <Route path="/Closedcase" component={Closedcase} />
            <Route path="/example" component={Example} />
            <Route path="/a" component={A} />
            <Route path="*" exact component={PageNotFound} />
          </Switch>
          {!onlineStatus && (
            <div className="offline-strip">
              You are currently offline.
            </div>
          )}
          {showOnlineNotification && (
            <div className="online-strip">
              You are back online.
            </div>
          )}
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
