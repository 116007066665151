import React from 'react';

const A = () => {
  const svgStyle = {
    height: '200px',
    width: 'fit-content',
    margin: '10px'
  };

  const containerStyle = {
    // jus
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  };

  return (
    <div style={containerStyle}>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="75" y="100" fill="red" fontSize="25">New Enquiry</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="60" y="100" fill="red" fontSize="25">Existing Enquiry</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="70" y="100" fill="red" fontSize="25">Vehicle Status</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="80" y="100" fill="red" fontSize="25">Master Lists</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="50" y="100" fill="red" fontSize="25">WAN Verification</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="30" y="100" fill="red" fontSize="25">Financial Accounting</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="82" y="100" fill="red" fontSize="25">Messaging</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="100" y="100" fill="red" fontSize="25">Reports</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="60" y="100" fill="red" fontSize="25">Admin Module</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="40" y="100" fill="red" fontSize="25">Asset Management</text>
        </svg>
      </a>
      <a href="" style={{ flex: '1 1 21%' }}>
        <svg style={svgStyle}>
          <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "lime" }} />
          <polygon points="280,190,280,80,270,180,160,190" style={{ fill: "lime" }} />
          <text x="70" y="100" fill="red" fontSize="25">Closed Cases</text>
        </svg>
      </a>
    </div>
  );
};

export default A;
