import React, { useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthState } = useContext(AuthContext);

  let history = useHistory();

  const login = () => {
    const data = { username: username, password: password };
    axios.post("https://rt-rl-api.aajkaa.in/auth/login", data).then((response) => {
      if (response.data.error) {
        alert(response.data.error);
      } else {
        localStorage.setItem("accessToken", response.data.token);
        setAuthState({
          username: response.data.username,
          id: response.data.id,
          usertype: response.data.usertype,
          whatsappnumber: response.data.whatsappnumber,
          status: true,
        });
        history.push('/')
      }
    });
  };

  return (
    <>
      <h1 className="text-primary text-center my-4 ">Login</h1>
      <div className="loginContainer border border-3 border-primary" style={styles.loginContainer}>
        <label style={styles.label}>Username:</label>
        <input
          type="text"
          onChange={(event) => {
            setUsername(event.target.value);
          }}
          style={styles.input}
          autoFocus
        />
        <label style={styles.label}>Password:</label>
        <input
          type="password"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          style={styles.input}
        />

        <button onClick={login} className="btn btn-primary mt-2 p-2" style={styles.button}>Login</button>
      </div>
    </>
  );
}

const styles = {
  loginContainer: {
    padding: "40px",
    maxWidth: "400px",
    margin: "auto",
  },
  label: {
    marginBottom: "5px",
  },
  input: {
    marginBottom: "10px",
    padding: "5px",
    width: "100%",
  },
  button: {
    width: "100%",
  },
};

export default Login;
