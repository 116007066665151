import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './common.css';
import LoadingBar from 'react-top-loading-bar'
import Loading from './Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../helpers/AuthContext";
import AssetList from './assetmanagement/AssetList';
import AssetStatus from './assetmanagement/AssetStatus';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";


function Assetmanagement() {

  let history = useHistory();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'Backspace':
          event.preventDefault();
          history.push('/')
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [selectedAsset, setSelectedAsset] = useState([]);

  const handleSelectAsset = (asset) => {
    setSelectedAsset(asset);
  };

  return (
    <div className='' style={{ overflow: "hidden" }}>
      <h2 className='text-primary text-center my-3'>Manage Assets/Consumables</h2>
      <Link to='/' className="ms-5 btn btn-primary"><i className="fa-solid fa-caret-left mx-2"></i>Back</Link>
      <div className='ms-5 mt-3 row'>
        <div className="col-md-5">
          <AssetList onSelectAsset={handleSelectAsset} />
        </div>
        <div className="col-md-6 ms-3">
          <AssetStatus asset={selectedAsset} />
        </div>
      </div>
    </div>
  )
}

export default Assetmanagement
