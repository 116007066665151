import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../common.css';
import LoadingBar from 'react-top-loading-bar'
import Loading from '../Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../helpers/AuthContext";

const AssetStatus = ({ asset }) => {


    const { authState } = useContext(AuthContext);

    const [people, setPeople] = useState([]);

    const [issuedto, setIssuedTo] = useState('');
    const [issuedon, setIssuedOn] = useState('');
    const [recivedback, setRecivedBack] = useState('');
    const [remarks, setRemarks] = useState('');

    const [disableUpdate, setDisableUpdate] = useState(false);
    const [disableAdd, setDisableAdd] = useState(false);

    const [editIndex, setEditIndex] = useState(null);

    const [showAdd, setShowAdd] = useState(false);

    const [showFindSearch, setShowFindSearch] = useState(false);

    const [columnOrder, setColumnOrder] = useState([['issuedto', 'text', false], ['issuedon', 'date', false], ['recivedback', 'date', false], ['remarks', 'text', false]]);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchPeople()
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));

        // Cleanup function to destroy tooltips when the component unmounts
        return () => {
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                const tooltip = window.bootstrap.Tooltip.getInstance(tooltipTriggerEl);
                if (tooltip) {
                    tooltip.dispose();
                }
            });
        };
    }, [asset]);

    const fetchPeople = async () => {
        if (asset.length != 0) {
            try {
                setLoading(true);
                setProgress(10);
                const response = await axios.post('https://rt-rl-api.aajkaa.in/assetstatus/', { assetid: asset[0] }, {
                    headers: { accessToken: localStorage.getItem("accessToken") }
                });
                setPeople(response.data);
                setLoading(false);
                setProgress(100);
            } catch (error) {
                console.error('Error fetching people', error);
            }
        }
    };

    const handleAddPerson = async (e) => {
        e.preventDefault();
        let person = { assetid: asset[0],issuedto, issuedon, recivedback, remarks, createdby: authState.username };

        // Add logging to debug

        if (editIndex !== null) {
            try {
                person = { issuedon, recivedback, remarks, updatedby: authState.username };
                await axios.put(`https://rt-rl-api.aajkaa.in/assetstatus/${people[editIndex].id}`, person, {
                    headers: { accessToken: localStorage.getItem("accessToken") },
                });
                setDisableAdd(false);
                resetFormAndReloadPeople();
            } catch (error) {
                console.error('Error updating person', error);
            }
        } else {
            try {
                if (asset[0] === null || asset[0] === undefined || asset[0] === "") {
                    alert("Please select a row in asset list");
                    return;
                }
                await axios.post('https://rt-rl-api.aajkaa.in/assetstatus/adddata', { person }, {
                    headers: { accessToken: localStorage.getItem("accessToken") },
                }).then((res) => {
                    toast.success('Data Added Successfully', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    resetFormAndReloadPeople();
                    setShowAdd(false)
                    setDisableUpdate(false)
                });
            } catch (error) {
                console.error('Error adding person', error);
            }
        }
    };


    const resetFormAndReloadPeople = async () => {
        setIssuedTo("")
        setIssuedOn("")
        setRecivedBack("")
        setRemarks("")

        setShowAdd(false);
        setShowFindSearch(false);
        setEditIndex(null);

        await fetchPeople();
    };

    const handleEditPerson = (index) => {
        const personToEdit = people[index];

        setEditIndex(index);

        setIssuedTo(personToEdit.issuedto)
        setIssuedOn(personToEdit.issuedon)
        setRecivedBack(personToEdit.recivedback)
        setRemarks(personToEdit.remarks)
    };

    const handleCancelBtn = () => {
        setDisableAdd(false)
        setEditIndex(null);

        setIssuedTo("")
        setIssuedOn("")
        setRecivedBack("")
        setRemarks("")
    };

    const handleDeletePerson = async (index) => {
        try {
            await axios.delete(`https://rt-rl-api.aajkaa.in/assetstatus/${people[index].id}`, {
                headers: { accessToken: localStorage.getItem("accessToken") },
            });
            fetchPeople();
        } catch (error) {
            console.error('Error deleting person', error);
        }
    };

    const handleDragStart = (e, position) => {
        e.dataTransfer.setData('text/plain', position);
    };

    const handleDrop = (e, targetPosition) => {
        e.preventDefault();
        const sourcePosition = e.dataTransfer.getData('text/plain');
        const newOrder = [...columnOrder];
        const [removedItem] = newOrder.splice(sourcePosition, 1);
        newOrder.splice(targetPosition, 0, removedItem);

        setColumnOrder(newOrder);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const columnType = {
        'issuedto': 'string',
        'issuedon': 'date',
        'recivedback': 'date',
        'remarks': 'string',
    }

    const [searchColumn, setSearchColumn] = useState("issuedto")
    const [searchSign, setSearchSign] = useState("contains")
    const [searchValue, setSearchValue] = useState("")
    const [signFromColType, setSignFromColType] = useState(false)


    const setSearchColType = (newValue) => {
        setSearchColumn(newValue);
        if (columnType[newValue] === "number") {
            setSignFromColType(true);
            setSearchSign("=")
        }

        if (columnType[newValue] === "string") {
            setSignFromColType(false);
            setSearchSign("contains")
        }
    };

    const filterSearch = (e) => {
        e.preventDefault();
        axios.post('https://rt-rl-api.aajkaa.in/assetstatus/search', {
            col: searchColumn,
            sign: searchSign,
            value: searchValue
        }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            setPeople(response.data)
        })
    }

    const sortPeople = (column, order) => {
        axios.post('https://rt-rl-api.aajkaa.in/assetstatus/sort', {
            col: column,
            order: order,
        }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            setPeople(response.data)
        })
    };

    const [sortColName, setSortColName] = useState("");
    const [sortOrder, setSortOrder] = useState("");

    const handleSort = (e, column) => {
        e.preventDefault();

        setSortColName(prevSortColName => {
            if (column !== prevSortColName || sortOrder === "") {
                setSortOrder("ASC");
            } else if (column === prevSortColName && sortOrder === "ASC") {
                setSortOrder("DESC");
            } else if (sortOrder === "DESC") {
                setSortOrder("ASC");
            }
            return column;
        });

    };

    useEffect(() => {
        if (sortColName != "" && sortOrder != "") {
            sortPeople(sortColName, sortOrder)
        }
    }, [sortColName, sortOrder]);


    const [progress, setProgress] = useState(0)

    const setters = {
        issuedto: setIssuedTo,
        issuedon: setIssuedOn,
        recivedback: setRecivedBack,
        remarks: setRemarks,
    };

    const settersvariable = {
        issuedto: issuedto,
        issuedon: issuedon,
        recivedback: recivedback,
        remarks: remarks,
    };


    const setterscolumns = {
        issuedto: "Issued To",
        issuedon: "Issued On",
        recivedback: "Recived Back",
        remarks: "Remarks",
    };


    const settersselections = {

    };


    const showOneHideAll = () => {
        setShowAdd(false)
        setShowFindSearch(false)
    }


    return (
        <div>
            {/* <ToastContainer /> */}
            <LoadingBar height={5} color='#f11946' progress={progress} />
            <h3 className='text-primary'>Asset Status</h3>
            <button className='btn btn-primary me-2' disabled={disableAdd} onClick={() => { showOneHideAll(); setShowAdd(showAdd ? false : true); setDisableUpdate(showAdd ? false : true); }}><i className="fa-solid fa-plus me-2"></i>Issue Asset</button>
            <button className='btn btn-success me-2' onClick={() => { showOneHideAll(); setShowFindSearch(showFindSearch ? false : true) }}> <i className="fa-solid fa-magnifying-glass me-2"></i>Find / Search</button>
            <button className='btn btn-warning me-2' onClick={() => fetchPeople()}><i className="fa-solid fa-eraser me-2"></i>Clear all find and sort</button>

            {
                showAdd && (
                    <div className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
                        <form className='d-flex flex-wrap gap-2' onSubmit={(e) => handleAddPerson(e)}>
                            <input type="text" placeholder='Issued To' value={issuedto} className='' onChange={(e) => setIssuedTo(e.target.value)} />
                            <input type="date" placeholder='Issued On' value={issuedon} className='' onChange={(e) => setIssuedOn(e.target.value)} />
                            <input type="date" placeholder='Cost' value={recivedback} className='' onChange={(e) => setRecivedBack(e.target.value)} />
                            <input type="text" placeholder='Remarks' value={remarks} className='' onChange={(e) => setRemarks(e.target.value)} />
                            <button className='btn btn-sm btn-primary' type='submit'>{editIndex !== null ? 'Update' : 'Add'}</button>
                        </form>
                    </div>
                )
            }

            {
                showFindSearch && (
                    <form onSubmit={(e) => filterSearch(e)} className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
                        <select onChange={(e) => { setSearchColType(e.target.value); }} className='me-2' style={{ height: "30px", width: "150px" }}>
                            {columnOrder.map((column, index) => (
                                column[1] == 'checkbox' ? <></> :
                                    <option value={column[0]}>{column[0]}</option>
                            ))}
                        </select>
                        <select onChange={(e) => { setSearchSign(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }}>
                            {signFromColType == true ?
                                <>
                                    <option value="="> = (Equal)</option>
                                    <option value="!="> != (Not Equal)</option>
                                    <option value="<="> {'<='} (Less Than or Equal)</option>
                                    <option value=">=">{'>='} (Greater Than or Equal)</option>
                                    <option value="<"> {'<'} (Less Than)</option>
                                    <option value=">"> {'>'} (Greater Than)</option>
                                </>
                                :
                                <>
                                    <option value="contains">Contains</option>
                                    <option value="doesnotcontains">Does not contains</option>
                                    <option value="beginwith">Begin with</option>
                                </>
                            }
                        </select>

                        <input type="text" onChange={(e) => { setSearchValue(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }} />
                        <button type='submit' className='btn btn-sm btn-success'>Search</button>
                    </form>
                )
            }
            <div className='mt-2' style={{ width: '100%', maxHeight: "50vh", overflow: 'auto', padding: "20px 150px 20px 20px", border: "1px solid black" }}>
                <table className='' style={{ width: '100%', tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                        <th style={{ padding: "0 10px", width: "100px", textAlign: "center", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Asset Name</th>
                        <th style={{ padding: "0 10px", width: "100px", textAlign: "center", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Asset SN</th>
                            {columnOrder.map((column, index) => (
                                <th
                                    key={column}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, index)}
                                    onDrop={(e) => handleDrop(e, index)}
                                    onDragOver={handleDragOver}
                                    onClick={(e) => { handleSort(e, column[0]) }}
                                    data-bs-toggle="tooltip" data-bs-placement="top" title={setterscolumns[column[0]]}
                                    style={{ padding: "0 10px", width: "100px", textAlign: "center", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                >
                                    <i className="fa-solid fa-sort me-2"></i>{setterscolumns[column[0]]}
                                </th>
                            ))}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={columnOrder.length + 1}>
                                    <Loading />
                                </td>
                            </tr>
                        ) : (
                            people.map((person, index) => (
                                <tr key={person.id}>
                                    <td style={{ textAlign: "center", maxWidth: '150px', border: "1px solid black", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{asset[1]}</td>
                                    <td style={{ textAlign: "center", maxWidth: '150px', border: "1px solid black", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{asset[2]}</td>
                                    {columnOrder.map((column) => (
                                        <td
                                            key={`${person.id}-${column}`}
                                            style={{ textAlign: "center", maxWidth: '150px', border: "1px solid black", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {editIndex === index ? (
                                                column[1] === 'select' ? (
                                                    settersselections[column[0]]
                                                ) : column[1] === 'text' || column[1] === 'date' ? (
                                                    <input
                                                        type={column[1]}
                                                        value={settersvariable[column[0]]}
                                                        onChange={(e) => setters[column[0]](e.target.value)}
                                                        style={{ width: '100%' }}
                                                        disabled={column[2]}
                                                    />
                                                ) : (<></>)
                                            ) : (
                                                column[0] !== 'asssetname' ? (
                                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={String(person[column[0]])}>{String(person[column[0]])}</span>
                                                ) : (
                                                    asset[1]
                                                )
                                            )}
                                        </td>
                                    ))}
                                    <td>
                                        {editIndex === index ? (
                                            <div style={{ display: "flex" }}>
                                                <button className='btn btn-sm btn-secondary ms-2' onClick={handleCancelBtn}>Cancel</button>
                                                <button className='btn btn-sm btn-success  ms-2' onClick={handleAddPerson}>Save</button>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex" }}>
                                                <button className='btn btn-sm btn-warning ms-2' disabled={disableUpdate} onClick={() => { setDisableAdd(true); handleEditPerson(index) }}>Edit</button>
                                                <button className='btn btn-sm btn-danger ms-2' onClick={() => handleDeletePerson(index)} style={{ marginRight: "50px" }}>Delete</button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AssetStatus
