import React , { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import {Link} from 'react-router-dom'

function Financialaccounting() {

  let history = useHistory();

useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
            return;
          }
        switch (event.key) {
            case 'Backspace':
                event.preventDefault();
                history.push('/')
                break;
            default:
                break;
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
}, []);


  return (
    <div>
      <div className="container">
        <h2 className='text-primary text-center my-3'>Update Accounts</h2>
        <Link to='/' className="btn btn-primary"><i class="fa-solid fa-caret-left mx-2"></i>Back</Link>
      </div>
    </div>
  )
}

export default Financialaccounting
