import React from 'react'

function Notallowed() {
    return (
        <>
            <div class="d-flex justify-content-center mt-4">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div className='mt-1 mx-2'>
                    <b>Loading...</b>
                </div>
            </div>
        </>
    )
}

export default Notallowed
