import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './common.css';
import LoadingBar from 'react-top-loading-bar'
import Loading from './Loading'

function Example() {
    const [people, setPeople] = useState([]);
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [number, setNumber] = useState('');
    const [yes, setYes] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [showFindSearch, setShowFindSearch] = useState(false);
    const [columnOrder, setColumnOrder] = useState([['name', 'text'], ['age', 'number'], ['number', 'text'], ['yes', 'checkbox']]);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchPeople();
    }, []);

    const fetchPeople = async () => {
        try {
            setProgress(10)
            const response = await axios.get('https://rt-rl-api.aajkaa.in/temp/');
            setPeople(response.data);
            console.log(response.data)
            setLoading(false)
            setProgress(100)
        } catch (error) {
            console.error('Error fetching people', error);
        }
    };

    const handleAddPerson = async () => {
        const person = { name, age, number, yes };
        if (editIndex !== null) {
            try {
                await axios.put(`https://rt-rl-api.aajkaa.in/temp/${people[editIndex].id}`, person);
            } catch (error) {
                console.error('Error updating person', error);
            }
        } else {
            try {
                await axios.post('https://rt-rl-api.aajkaa.in/temp/', person);
            } catch (error) {
                console.error('Error adding person', error);
            }
        }
        resetFormAndReloadPeople();
    };

    const resetFormAndReloadPeople = async () => {
        setName('');
        setAge('');
        setNumber('');
        setYes(false);
        setShowAdd(false);
        setShowFindSearch(false);
        setEditIndex(null);
        await fetchPeople();
    };

    const handleEditPerson = (index) => {
        const personToEdit = people[index];
        setEditIndex(index);
        setName(personToEdit.name);
        setAge(personToEdit.age);
        setNumber(personToEdit.number);
        setYes(personToEdit.yes);
    };

    const handleDeletePerson = async (index) => {
        try {
            await axios.delete(`https://rt-rl-api.aajkaa.in/temp/${people[index].id}`);
            fetchPeople();
        } catch (error) {
            console.error('Error deleting person', error);
        }
    };

    const handleDragStart = (e, position) => {
        e.dataTransfer.setData('text/plain', position);
    };

    const handleDrop = (e, targetPosition) => {
        e.preventDefault();
        const sourcePosition = e.dataTransfer.getData('text/plain');
        const newOrder = [...columnOrder];
        const [removedItem] = newOrder.splice(sourcePosition, 1);
        newOrder.splice(targetPosition, 0, removedItem);

        setColumnOrder(newOrder);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const columnType = {
        'name': 'string',
        'age': 'number',
        'number': 'number',
        'yes': "checkbox"
    }

    const [searchColumn, setSearchColumn] = useState("name")
    const [searchSign, setSearchSign] = useState("contains")
    const [searchValue, setSearchValue] = useState("")
    const [signFromColType, setSignFromColType] = useState(false)


    const setSearchColType = (newValue) => {
        setSearchColumn(newValue);
        if (columnType[newValue] === "number") {
            setSignFromColType(true);
            setSearchSign("=")
        }
        if (columnType[newValue] === "string") {
            setSignFromColType(false);
            setSearchSign("contains")
        }
    };


    const filterSearch = (e) => {
        e.preventDefault();
        console.log(searchColumn, searchSign, searchValue)
        axios.post('https://rt-rl-api.aajkaa.in/temp/search', {
            col: searchColumn,
            sign: searchSign,
            value: searchValue
        }).then((response) => {
            setPeople(response.data)
        })
    }

    const sortPeople = (column, order) => {
        console.log(column, order)
        axios.post('https://rt-rl-api.aajkaa.in/temp/sort', {
            col: column,
            order: order,
        }).then((response) => {
            setPeople(response.data)
        })
    };

    const [sortColName, setSortColName] = useState("");
    const [sortOrder, setSortOrder] = useState("");

    const handleSort = (e, column) => {
        e.preventDefault();

        setSortColName(prevSortColName => {
            if (column !== prevSortColName || sortOrder === "") {
                setSortOrder("ASC");
            } else if (column === prevSortColName && sortOrder === "ASC") {
                setSortOrder("DESC");
            } else if (sortOrder === "DESC") {
                setSortOrder("ASC");
            }
            return column;
        });

    };

    useEffect(() => {
        if (sortColName != "" && sortOrder != "") {
            sortPeople(sortColName, sortOrder)
        }
        console.log(sortColName, sortOrder);
    }, [sortColName, sortOrder]);


    const [progress, setProgress] = useState(0)

    const setters = {
        name: setName,
        age: setAge,
        number: setNumber,
        yes: setYes
    };

    const settersvariable = {
        name: name,
        age: age,
        number: number,
        yes: yes
    };

    return (
        <div>
            <LoadingBar height={5} color='#f11946' progress={progress} />
            <button onClick={() => setShowAdd(showAdd ? false : true)}>Add Person</button>
            <button onClick={() => setShowFindSearch(showFindSearch ? false : true)}>Find / Search</button>
            <button onClick={() => fetchPeople()}>Clear all find and sort</button>

            {showAdd && (
                <div>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                    />
                    <input
                        type="number"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        placeholder="Age"
                    />
                    <input
                        type="text"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        placeholder="Number"
                    />

                    <input
                        type="checkbox"
                        value={yes}
                        onChange={(e) => setYes(yes ? false : true)}
                    />
                    <button onClick={handleAddPerson}>{editIndex !== null ? 'Update' : 'Add'}</button>
                </div>
            )}

            {showFindSearch && (
                <form onSubmit={(e) => filterSearch(e)}>
                    <select onChange={(e) => { setSearchColType(e.target.value); }}>
                        <option value="name">name</option>
                        <option value="age">age</option>
                        <option value="number">number</option>
                    </select>
                    <select onChange={(e) => { setSearchSign(e.target.value) }} required>
                        {signFromColType == true ?
                            <>
                                <option value="="> = (Equal)</option>
                                <option value="!="> != (Not Equal)</option>
                                <option value="<="> {'<='} (Less Than or Equal)</option>
                                <option value=">=">{'>='} (Greater Than or Equal)</option>
                                <option value="<"> {'<'} (Less Than)</option>
                                <option value=">"> {'>'} (Greater Than)</option>
                            </>
                            :
                            <>
                                <option value="contains">Contains</option>
                                <option value="doesnotcontains">Does not contains</option>
                                <option value="beginwith">Begin with</option>
                            </>
                        }
                    </select>

                    <input type="text" onChange={(e) => { setSearchValue(e.target.value) }} required />
                    <button type='submit'>Search</button>
                </form>
            )}

            <table style={{ "height": "200px" }}>
                <thead>
                    <tr>
                        {columnOrder.map((column, index) => (
                            <th
                                key={column}
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDrop={(e) => handleDrop(e, index)}
                                onDragOver={handleDragOver}
                                onClick={(e) => { handleSort(e, column[0]) }}
                                style={{ width: '150px' }} // Set fixed width
                            >
                                {column[0].toUpperCase()}
                            </th>
                        ))}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={columnOrder.length + 1}> {/* Adjust the colspan based on the number of columns */}
                                <Loading />
                            </td>
                        </tr>
                    ) : (
                        people.map((person, index) => (
                            <tr key={person.id}>
                                {columnOrder.map((column) => (
                                    <td key={`${person.id}-${column}`} style={{ maxWidth: '150px', "border": "1px solid black", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {editIndex === index ? (
                                            column[1] === 'checkbox' ? (
                                                <input
                                                    type="checkbox"
                                                    checked={settersvariable[column[0]]} // Use state variable
                                                    onChange={(e) => setters[column[0]](e.target.checked)} // Update state directly
                                                />
                                            ) : (
                                                <input
                                                    type={column[1]}
                                                    value={settersvariable[column[0]]}
                                                    onChange={(e) => setters[column[0]](e.target.value)}
                                                    style={{ width: '100%' }}
                                                />
                                            )
                                        ) : (
                                            column[1] === "checkbox" ? (
                                                <input
                                                    type="checkbox"
                                                    checked={person[column[0]]}
                                                />
                                            ) : (
                                                String(person[column[0]])
                                            )
                                        )}
                                    </td>
                                ))}
                                <td>
                                    {editIndex === index ? (
                                        <button onClick={handleAddPerson}>Save</button>
                                    ) : (
                                        <>
                                            <button onClick={() => handleEditPerson(index)}>Edit</button>
                                            <button onClick={() => handleDeletePerson(index)}>Delete</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Example;
